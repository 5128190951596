const qualificationsMap = [
  {
    label: 'Licensed Cosmetologist',
    option: 'cosmetology',
  },
  {
    label: 'Hairdressing NVQ',
    option: 'hairdressingnvq',
  },
  {
    label: 'Hairdressing & Barbering NVQ',
    option: 'hairdressingbarberingnvq',
  },
  {
    label: 'Hairdressing & Beauty NVQ',
    option: 'hairdressingbeautynvq',
  },
  {
    label: 'Licensed Trichologist',
    option: 'trichology',
  },
  {
    label: 'Diploma for Advanced & Creative Hair Professionals',
    option: 'diplomacreativepros',
  },
  {
    label: 'Cut it Kinky Certified',
    option: 'cutitkinky',
  },
  {
    label: 'DevaCurl Certified',
    option: 'devacurl',
  },
  {
    label: 'Mizani AirCut ',
    option: 'mizaniaircut',
  },
  {
    label: 'Ouidad Certified',
    option: 'Ouidad',
  },
  {
    label: 'Ruka Pro Certified',
    option: 'rukapro',
  },
  {
    label: 'Savvy Color Certified',
    option: 'savvycolor',
  },
  {
    label: 'Forever Curls Academy',
    option: 'forevercurls',
  },
];

export const qualificationKeyToLabel = key => {
  const qualification = qualificationsMap.find(qualification => qualification.option === key);
  return qualification ? qualification.label : null;
};
