import React from 'react';
import { string, func, bool, object } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { Award, Briefcase, MapPin } from 'react-feather';

import {
  AspectRatioWrapper,
  NamedLink,
  ResponsiveImage,
  AvatarSmall,
  RatingStars,
} from '../../components';

import css from './ListingCard.module.css';
import { qualificationKeyToLabel } from '../../util/qualifications';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { listing = null, reviews = null, displayReview = false, locationOnly = false } = props;
  const config = useConfiguration();
  const {
    className = null,
    rootClassName = null,
    renderSizes = null,
    setActiveListing = null,
  } = props;
  const classes = classNames(
    rootClassName || css.root,
    className,
    locationOnly && css.locationOnly
  );
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', description = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const firstImage =
    currentListing.images && currentListing.images?.length > 0 ? currentListing.images[0] : null;
  const secondImage =
    currentListing.images && currentListing.images?.length > 1 ? currentListing.images[1] : null;
  const stylistQualifications = author.attributes.profile.publicData.stylistqualifications;
  const stylistExperience = author.attributes.profile.publicData.hairstylisttime;
  const location = currentListing.attributes?.publicData?.location?.address;
  const isAcuityStylist = author.attributes.profile.publicData.Acuity_yes_no === 'Yes';

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  if (locationOnly) {
    return (
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <div className={classNames(css.info, css.infoWithoutBorder)}>
          <div className={css.mainInfo}>
            <div className={css.headingRow}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
            </div>
            <div className={css.authorInfo}>
              <MapPin color="white" size={18} style={{ fill: '#E25E38', marginTop: 6 }} />
              {location ? <div className={css.authorInfo}>{location}</div> : 'Mobile'}
            </div>
          </div>
        </div>
      </NamedLink>
    );
  }

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        {secondImage ? (
          <LazyImage
            rootClassName={classNames(css.rootForImage, css.secondaryImage)}
            alt={title}
            image={secondImage}
            variants={variants}
            sizes={renderSizes}
          />
        ) : null}
      </AspectRatioWrapper>
      <div className={classNames(css.info)}>
        <div className={css.mainInfo}>
          <div className={css.headingRow}>
            <AvatarSmall user={author} className={css.providerAvatar} disableProfileLink />
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
          </div>
          {!isAcuityStylist && displayReview && reviews && (
            <RatingStars className={css.ratingStars} reviews={reviews} listingId={id} />
          )}
          {stylistQualifications?.length > 0
            ? stylistQualifications.map((qualification, index) => {
                return (
                  <div key={index} className={css.authorInfo}>
                    <Award color="white" size={18} style={{ fill: '#E25E38', marginTop: 6 }} />
                    {qualificationKeyToLabel(qualification) || qualification}
                  </div>
                );
              })
            : null}
          {stylistExperience ? (
            <div className={css.authorInfo}>
              <Briefcase color="white" size={18} style={{ fill: '#E25E38', marginTop: 6 }} />
              {stylistExperience} years of experience
            </div>
          ) : null}

          <div className={css.authorInfo}>
            <MapPin color="white" size={18} style={{ fill: '#E25E38', marginTop: 6 }} />
            {location ? <div className={css.authorInfo}>{location.split(',')[0]}</div> : 'Mobile'}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  reviews: object,
  displayRating: bool,
};

export default injectIntl(ListingCardComponent);
