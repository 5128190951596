import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SubscriptionAlert.module.css';

const SubscriptionAlert = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className={css.subscriptionAlert}>
      <button className={css.subscriptionBar} onClick={toggleExpand} style={{ padding: '0px' }}>
        <p className={classNames(css.ownListingText, css.missingPayoutDetailsText)}>
          Please subscribe to start accepting inquiries.
        </p>
        <div className={classNames(css.right, css.ownListingText)}>
          <span>Choose subscription</span>
          <span className={classNames(css.arrow, { [css.expanded]: isExpanded })}>▼</span>
        </div>
      </button>
      {isExpanded && (
        <div className={css.subscriptionOptions}>
          <a
            href="https://buy.stripe.com/28o15kcvW5eU7TO7sv"
            target="_blank"
            rel="noopener noreferrer"
            className={css.subscriptionLink}
          >
            Monthly Subscription
          </a>
          <a
            href="https://buy.stripe.com/aEUg0ecvWePu7TOeUW"
            target="_blank"
            rel="noopener noreferrer"
            className={css.subscriptionLink}
          >
            Yearly Subscription
          </a>
        </div>
      )}
    </div>
  );
};

export default SubscriptionAlert;
