import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { Award, Briefcase } from 'react-feather';

import { NamedLink, AvatarLarge } from '../../components';
import { qualificationKeyToLabel } from '../../util/qualifications';
import css from './AuthorCard.module.css';

const AuthorCard = ({ className = null, rootClassName = null, listing }) => {
  const classes = classNames(rootClassName || css.root, className);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const stylistQualifications = author.attributes.profile.publicData.stylistqualifications;
  const stylistExperience = author.attributes.profile.publicData.hairstylisttime;

  return (
    <NamedLink className={classes} name="ProfilePage" params={{ id: author.id.uuid }}>
      <div className={css.content}>
        <AvatarLarge user={author} className={css.avatar} disableProfileLink />
        <div className={css.info}>
          <div className={css.name}>{authorName}</div>
          {stylistQualifications?.length > 0 && (
            <div className={css.qualifications}>
              {stylistQualifications.map((qualification, index) => (
                <div key={index} className={css.qualification}>
                  <Award color="white" size={18} style={{ fill: '#E25E38' }} />
                  <span>{qualificationKeyToLabel(qualification) || qualification}</span>
                </div>
              ))}
            </div>
          )}
          {stylistExperience && (
            <div className={css.experience}>
              <Briefcase color="white" size={18} style={{ fill: '#E25E38' }} />
              <span>{stylistExperience} years of experience</span>
            </div>
          )}
        </div>
      </div>
    </NamedLink>
  );
};

AuthorCard.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
};

export default AuthorCard;
