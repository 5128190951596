import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './OpeningHours.module.css';

const OpeningHours = ({ timeSlots }) => {
  if (!timeSlots || Object.keys(timeSlots).length === 0) {
    return null;
  }

  const currentMonth = Object.keys(timeSlots)[0];
  const monthData = timeSlots[currentMonth];
  if (monthData.fetchTimeSlotsInProgress) {
    return (
      <div className={css.root}>
        <h3 className={css.title}>
          <FormattedMessage id="OpeningHours.title" defaultMessage="This week's availability" />
        </h3>
        <p>Loading availability...</p>
      </div>
    );
  }

  if (monthData.fetchTimeSlotsError) {
    return (
      <div className={css.root}>
        <h3 className={css.title}>
          <FormattedMessage id="OpeningHours.title" defaultMessage="This week's availability" />
        </h3>
        <p>Error loading opening hours. Please try again later.</p>
      </div>
    );
  }

  if (!monthData.timeSlots || monthData.timeSlots.length === 0) {
    return (
      <div className={css.root}>
        <h3 className={css.title}>
          <FormattedMessage id="OpeningHours.title" defaultMessage="This week's availability" />
        </h3>
        <p>Time slots unavailable.</p>
      </div>
    );
  }

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const openingHours = daysOfWeek.map(day => {
    const daySlots = monthData.timeSlots.filter(slot => {
      const slotDate = new Date(slot.attributes.start);
      return slotDate.toLocaleString('en-us', { weekday: 'long' }) === day;
    });

    if (daySlots.length === 0) {
      return { day, hours: 'No availability' };
    }

    const openTime = new Date(daySlots[0].attributes.start).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    const closeTime = new Date(daySlots[daySlots.length - 1].attributes.end).toLocaleTimeString(
      [],
      { hour: '2-digit', minute: '2-digit' }
    );

    return { day, hours: `${openTime} - ${closeTime}` };
  });

  return (
    <div className={css.root}>
      <h3 className={css.title}>
        <FormattedMessage id="OpeningHours.title" defaultMessage="This week's availability" />
      </h3>
      <ul className={css.list}>
        {openingHours.map(({ day, hours }) => (
          <li key={day} className={css.listItem}>
            <span className={css.day}>{day}</span>
            <span className={classNames(css.hours, { [css.closed]: hours === 'Closed' })}>
              {hours}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

OpeningHours.propTypes = {
  timeSlots: PropTypes.object,
};

export default OpeningHours;
