import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapboxReverseGeocoder = ({ lat, lng, className, fullAddress = false }) => {
  const [address, setAddress] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    const reverseGeocode = async () => {
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`
        );
        const data = await response.json();
        if (data.features && data.features.length > 0) {
          setAddress(fullAddress ? data.features[0].place_name : data.features[0].text);
        } else {
          setAddress('No results found');
        }
      } catch (error) {
        console.error('Error during reverse geocoding:', error);
        setAddress('Error fetching address');
      }
    };

    if (isVisible) {
      reverseGeocode();
    }
  }, [lat, lng, fullAddress, isVisible]);

  return (
    <div ref={ref} className={className}>
      {isVisible ? address : 'Loading...'}
    </div>
  );
};

export default MapboxReverseGeocoder;
